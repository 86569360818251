<template>
<div class="apCodeUpload-page-content winner-page__hero">
   <div class="main-block position-relative">
      <div class="container-xl py-lg-5">
        <div class="row" :class="winnerType == 'heti' ? 'winner-bg--heti':''">
            <div class="col-12 pt-lg-5 custom-padding">
                <div class="winner-bg">
                    <div class="row position-relative">
                        <img src="@/assets/imgs/winner-page-tunderke.png" alt="lego" class="img-fluid tunderke"/>
                        <img src="@/assets/imgs/winner-page-mano.png" alt="lego" class="img-fluid mano"/>
                        <div class="col-12 col-lg-6">
                            <div class="text-center winner-page--text d-flex justify-content-center align-items-center flex-column h-100">
                                <img src="@/assets/imgs/grat.svg" alt="Gratulálunk" class="img-fluid mb-4"/>
                                <p class="text-black fs-22 mb-2 font-primary fw-bold">Érvényes pályázat esetén nyereménye lehet</p>            
                                <p class="text-black fs-22 text-center font-primary fw-bold">
                                     <!-- pc -->
                                     <img v-if="winnerType == 'daily'" src="@/assets/imgs/daily.svg" alt="napi nyememény" class="img-fluid"/>
                                     <img v-if="winnerType == 'weekly_borond'" src="@/assets/imgs/weekly_borond.svg" alt="napi nyememény" class="img-fluid"/>
                                     <img v-if="winnerType == 'weekly_hatizsak'" src="@/assets/imgs/weekly_hatizsak.svg" alt="napi nyememény" class="img-fluid"/>
                                     <img v-if="winnerType == 'monthly'" src="@/assets/imgs/monthly.svg" alt="napi nyememény" class="img-fluid "/>
                                     <img v-if="winnerType == 'main'" src="@/assets/imgs/main.svg" alt="napi nyememény" class="img-fluid"/>

                                    
                                     <span class="text-black fs-22 pt-3 d-block" v-if="winnerType == 'main'">Legoland Dubai és Waterpark belépővel!</span>
                                 <!--   <span class="winner-page__hero__winning text-uppercase" v-if="winnerType == 'heti'">1 db 100.000 FT pénznyeremény</span><br>
                                    <span v-if="winnerType == 'heti'">bankszámlára utalva</span>
                                    <span class="winner-page__hero__winning " v-if="winnerType == 'fodij'">1 db 500.000 Ft értékű IBUSZ vásárlási utalvány</span>
                               --> </p>
                            </div>
                        </div>   
                        <div class="col-12 col-lg-4 offset-lg-2 mx-auto">
                            <img v-if="winnerType == 'daily'" src="@/assets/imgs/daily-pic.png" alt="napi nyememény" class="img-fluid mx-auto d-none d-md-block pt-5 pt-lg-0 mt-5 mt-lg-0"/>
                            <img v-if="winnerType == 'weekly_borond'" src="@/assets/imgs/weekly_borond-pic.png" alt="napi nyememény" class="img-fluid mx-auto d-none d-md-block pt-5 pt-lg-0 mt-5 mt-lg-0"/>
                            <img v-if="winnerType == 'weekly_hatizsak'" src="@/assets/imgs/weekly_hatizsak-pic.png" alt="napi nyememény" class="img-fluid mx-auto d-none d-md-block pt-5 pt-lg-0 mt-5 mt-lg-0"/>
                            <img v-if="winnerType == 'monthly'" src="@/assets/imgs/monthly-pic.png" alt="napi nyememény" class="img-fluid mx-auto d-none d-md-block pt-5 pt-lg-0 mt-5 mt-lg-0"/>
                            <img v-if="winnerType == 'main'" src="@/assets/imgs/main-pic.png" alt="napi nyememény" class="img-fluid mx-auto d-none d-md-block pt-5 pt-lg-0 mt-5 mt-lg-0"/></div>

                            <!-- mobile -->
                            <img v-if="winnerType == 'daily'" src="@/assets/imgs/daily-mobile.png" alt="napi nyememény" class="img-fluid d-block d-md-none"/>
                            <img v-if="winnerType == 'weekly_borond'" src="@/assets/imgs/weekly_borond-mobile.png" alt="napi nyememény" class="img-fluid d-block d-md-none"/>
                            <img v-if="winnerType == 'weekly_hatizsak'" src="@/assets/imgs/weekly_hatizsak-mobile.png" alt="napi nyememény" class="img-fluid d-block d-md-none"/>
                            <img v-if="winnerType == 'monthly'" src="@/assets/imgs/monthly-mobile.png" alt="napi nyememény" class="img-fluid d-block d-md-none"/>
                            <img v-if="winnerType == 'main'" src="@/assets/imgs/main-mobile.png" alt="napi nyememény" class="img-fluid d-block d-md-none"/>
                    </div>
                </div>
            </div>
        </div>   
    </div>    
  </div>
   <!-- <div class="bg-primary">
        <div class="col-12 col-lg-8 mx-auto text-center fs-14 fs-md-18 text-white py-5 d-flex flex-wrap justify-content-center align-items-center ">                                
            <p class="mb-0">A vásárlást igazoló eredeti bizonylatot sértetlen, ép állapotban <b>2024. szeptember 28-ig</b> meg kell őrizni. <br>A képek csak illusztrációk.</p>
        </div>    
    </div>
  <Ribbon/>-->
</div>
</template>

<script>
//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    props:['winnerType']
}
</script>